import React, { useEffect, lazy, Suspense } from "react"
import AppStore, { EAuthState, EUserInitState } from "@awailio/backend/appStore/AppStore"

import TitleBar from "@awailio/main/titleBar/TitleBar"
import ThingsExplorer from "@awailio/main/thingsExplorer/ThingsExplorer"
import LabelCreator from "@awailio/main/labelCreator/LabelCreator"
import CardDetail from "@awailio/main/cardDetail/CardDetail"
import Button, { EButtonType } from "@awailio/main/buttons/Button"
import ThingEditor, { EThingEditorTask } from "@awailio/main/thingEditor/ThingEditor"
import ScannerMain from "@awailio/main/scanner/ScannerMain"

// import UILibrary = lazy(() => import("./"))

import { BrowserRouter, Route } from "react-router-dom"

// import Join from "@awailio/main/auth/Join"
import Login from "@awailio/main/auth/Login"

import "./app.css"
import "./components/main/colors/colors.css"
import "./components/main/buttons/button.css"
import UserProfile from "@awailio/main/userProfile/UserProfile"

const UILibrary = lazy(() => import("@awailio/main/uiLibrary/UILibrary"))

const App: React.FC = () => {
  const appStore = AppStore.useStore()

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      document.title = "DEV:awail.io"
    } else {
      document.title = "awail.io"
    }
  }, [])

  const selectContent = () => {
    if (
      appStore.authState === EAuthState.loggedIn &&
      appStore.userInitState === EUserInitState.intitialized &&
      appStore.userGroup === "betaphase01"
    ) {
      return (
        <>
          <Route exact path={`/`}>
            <ThingsExplorer></ThingsExplorer>
          </Route>
          {/* <Route exact path={`/`}></Route> */}
          <Route exact path={`/newthing`}>
            <ThingEditor task={EThingEditorTask.addThing}></ThingEditor>
          </Route>
          <Route exact path={`/profile`}>
            <UserProfile></UserProfile>
          </Route>
          <Route exact path={`/labels`}>
            <LabelCreator />
          </Route>
          <Route exact path="/getthing/:idtype/:uuid" component={CardDetail}></Route>
        </>
      )
    } else if (
      appStore.authState === EAuthState.loggedIn &&
      appStore.userInitState === EUserInitState.intitialized &&
      appStore.userGroup !== "betaphase01"
    ) {
      return (
        <>
          <h1> you are on the waiting list</h1>
          <button className={`danger solid`} onClick={() => appStore.deletUserAccount()}>
            remove me from the waiting list
          </button>
        </>
      )
    } else if (
      appStore.authState === EAuthState.loggedIn &&
      appStore.userInitState !== EUserInitState.intitialized
    ) {
      return <h1>pending</h1>
    } else if (appStore.authState === EAuthState.loggedOut) {
      return <Login></Login>
    }
  }

  //###################################################
  //
  //      Errors
  //
  //###################################################
  const getErrors = () => {
    if (Object.keys(appStore.errorQueue).length > 0) {
      return (
        <div className="messagesBlockWrapper">
          {Object.keys(appStore.errorQueue).map((errorID, id) => {
            return (
              <div className="message error" key={errorID}>
                <div className="errorText">{appStore.errorQueue[errorID]}</div>
              </div>
            )
          })}
          <div className="clearMessagesWrapper">
            <Button type={EButtonType.darkOutline} onClick={() => appStore.removeError("", true)}>
              <i className="material-icon" style={{ color: "var(--danger)" }}>
                cancele
              </i>
              clear errors
            </Button>
          </div>
        </div>
      )
    }
  }

  //###################################################
  //
  //      messages
  //
  //###################################################

  const getMessages = () => {
    return Object.keys(appStore.uploadQueue).map((thingId, id) => {
      return (
        <div className="messagesBlockWrapper" key={id}>
          {Object.keys(appStore.uploadQueue[thingId]).map((messageID, id) => {
            return (
              <div key={id} className="message">
                <div
                  className="uploadProgress"
                  style={{ width: `${appStore.uploadQueue[thingId][messageID]}%` }}
                ></div>
                <div className="uploadProgressInfo">{messageID}</div>
              </div>
            )
          })}
        </div>
      )
    })
  }

  return (
    <BrowserRouter>
      <TitleBar></TitleBar>
      <div className="app__ContentArea">
        <ScannerMain></ScannerMain>
        {selectContent()}
      </div>

      {process.env.NODE_ENV === "development" ? (
        <div className="debugDiv">
          <div>--- DEBUG ---</div>
          <div>{JSON.stringify(appStore.uploadQueue)}</div>

          <li>userDisplayName: {`${appStore.userDisplayName}`}</li>
          <li>userEmail: {`${appStore.userEmail}`}</li>
          <li>userUid: {`${appStore.userUid}`}</li>
          <li>authState: {`${appStore.authState}`}</li>
          <li>userGroup: {`${appStore.userGroup}`}</li>
          <li>userInitState: {`${appStore.userInitState}`}</li>
          <li>userDBState: {`${appStore.userDBState}`}</li>

          <div>-------------</div>
        </div>
      ) : null}

      <div className="appMessages">
        {getErrors()}
        {/* {getMessages()} */}
      </div>

      {/* <div className={`errorMsg ${Object.keys(appStore.errorQueue).length > 0 ? "show" : ""}`}>
        Error: {appStore.errorMsg}
      </div> */}
      {process.env.NODE_ENV === "development" ? (
        <Suspense fallback={<div>loading ...</div>}>
          <Route extact path="/uilibrary" component={UILibrary}></Route>
        </Suspense>
      ) : null}
    </BrowserRouter>
  )
}

export default App
