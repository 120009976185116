import React, { useState, useEffect, useContext } from "react"

// spacer --> //

import "./layout.css"
interface Ispacer {
  vspace?: number
  hspace?: number
}

export const Spacer: React.FunctionComponent<Ispacer> = (props) => {
  return <div style={{ width: `${props.vspace}rem`, height: `${props.hspace}rem` }}></div>
}

// <-- spacer //
