import React, { useState } from "react"
import { useHistory, useLocation } from "react-router"
import AppStore from "@awailio/backend/appStore/AppStore"
import Button, { EButtonType } from "@awailio/main/buttons/Button"
import { Spacer } from "@awailio/main/layout/Layout"

import "./titleBar.css"

interface ITitleBar {}
// let history = createBrowserHistory()
const TitleBar: React.FunctionComponent<ITitleBar> = (props) => {
  const appStore = AppStore.useStore()
  const history = useHistory()
  const [showMenue, setShowMenue] = useState(false)

  const mainButton = (pathname: string) => {
    const subroutes = ["newthing", "getthing"]
    if (
      pathname.includes("newthing") ||
      pathname.includes("getthing") ||
      pathname.includes("labels") ||
      pathname.includes("profile")
    ) {
      return (
        <Button minWidth={10} type={EButtonType.plain} onClick={() => history.push("/")}>
          <i className="material-icon">arrow_back_ios</i>back
        </Button>
      )
    } else {
      return (
        <Button minWidth={10} type={EButtonType.primary} onClick={() => history.push("/newthing")}>
          <i className="material-icon">add</i>add Thing
        </Button>
      )
    }
  }

  return (
    <div className={"titlebar__wrapper"}>
      <div className={`titleBar__submenue ${showMenue ? "show" : null}`}>
        <div className="titleBar__submenueList">
          <div
            className="entry"
            onClick={() => {
              setShowMenue(false)
              history.push("/labels")
            }}
          >
            Print Labels
          </div>
          <div
            className="entry"
            onClick={() => {
              setShowMenue(false)
              history.push("/profile")
            }}
          >
            Profile
          </div>
          <div className="entry"></div>
          <div
            className="entry"
            onClick={() => {
              setShowMenue(false)
              history.push("/")
              appStore.logout()
            }}
          >
            Logout
          </div>
        </div>
      </div>
      <div className="titleBar__main">
        <div className={`titleBar__mainAddThing`}>{mainButton(useLocation().pathname)}</div>
        {appStore.authState === "loggedIn" ? (
          <>
            <Spacer vspace={3}></Spacer>

            <button
              className=" material-icons float"
              onClick={() => appStore.toggleScanner(!appStore.showScanner)}
            >
              qr_code_scanner
            </button>
          </>
        ) : null}

        {/* <div onClick={() => history.push("/")}>awail.io</div> */}
        {/* <div>{showAddThing(useLocation().pathname)}</div> */}
        <Spacer vspace={3}></Spacer>
        {/* <div>
          {appStore.thingsUnused.length}
          {appStore.thingsUnused.length > 1 ? " Cards left" : " Card left"}
        </div> */}
        <p></p>
      </div>

      {appStore.authState === "loggedIn" ? (
        <button
          className=" material-icons titleBar__floatingMainMenue float"
          onBlur={() => {
            setTimeout(() => {
              setShowMenue(false)
            }, 100)
          }}
          onClick={() => {
            setShowMenue((prev) => !prev)
          }}
        >
          {showMenue ? "close" : "menu"}
        </button>
      ) : null}
    </div>
  )
}

export default TitleBar
