import React, { useEffect, useState } from "react"
import AppStore, {
  TThingData,
  TThingUploadFiles,
  TFile,
  TPdfs,
} from "@awailio/backend/appStore/AppStore"
import { useHistory } from "react-router"
import Button, { EButtonType } from "@awailio/main/buttons/Button"
import { pdfIcon, cleanFileName, TPDFStorage } from "@awailio/main/thingEditor/ThingEditor"

import "./thingCard.css"
import { pdf } from "@react-pdf/renderer"

interface IThingCard {
  id: string
  data: TThingData
  fileUploads?: TThingUploadFiles
}

const ThingCard: React.FunctionComponent<IThingCard> = (props) => {
  const appStore = AppStore.useStore()

  const history = useHistory()
  const { id, data, fileUploads } = props
  const [files, setFiles] = useState<Array<string>>([])
  const [pdfs, setPdfs] = useState<TPDFStorage>({})
  const [changeType, setChangeType] = useState<string>("")
  const [uploadWIP, setUplaodWIP] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  let handle = setTimeout(() => {}, 0)

  useEffect(() => {
    if (data.changeType === "modified" || data.changeType === "added") {
      setChangeType("modified")
      clearTimeout(handle)
      handle = setTimeout(() => {
        setChangeType("")
      }, 3000)
    }
    return () => clearTimeout(handle)
  }, [data])

  // images
  useEffect(() => {
    if (appStore.userStorage) {
      const filesList = appStore.userStorage.child(
        `userData/${appStore.userUid}/files/${id}/images`
      )

      filesList.listAll().then((res) => {
        const getFilePR: Array<Promise<any>> = []

        console.log("fileList ", res)

        res.items.map((item) => {
          const getFileUrl: Promise<string> = item.getDownloadURL()
          getFilePR.push(getFileUrl)
        })
        Promise.all(getFilePR).then((res) => {
          res.sort()
          setFiles(res)
        })
      })
    }
  }, [uploadWIP])

  // pdfs
  useEffect(() => {
    if (appStore.userStorage) {
      const filesList = appStore.userStorage.child(`userData/${appStore.userUid}/files/${id}/files`)

      filesList.listAll().then((res) => {
        filesList.listAll().then((pdfList) => {
          pdfList.items.map((item) =>
            item.getDownloadURL().then((url) => {
              setPdfs((_pdfs) => ({ ..._pdfs, [item.fullPath]: { url: url, name: item.name } }))
            })
          )
        })
      })
    }
  }, [uploadWIP])

  useEffect(() => {
    let progress = 0
    if (fileUploads) {
      const uploadsCount = Object.keys(fileUploads).length

      Object.keys(fileUploads).map((file, id) => {
        progress += fileUploads[file]
      })

      progress = progress / uploadsCount
      setUploadProgress(progress)
      if (progress !== 0 && progress !== 100) {
        setUplaodWIP(true)
      } else {
        setUplaodWIP(false)
      }
    }
  }, [props.fileUploads])

  const getPdfs = () => {
    const pdfPathes = Object.keys(pdfs)

    return pdfPathes?.map((path, id) => {
      return (
        <div
          key={id}
          className="thingEditor__pdfListItem pad"
          onClick={() => window.open(pdfs[path].url, "_blank")}
        >
          <div className="thingEditor__pdfIcon">
            {pdfIcon}
            <span className="tooltipText">{cleanFileName(pdfs[path].name)}</span>
          </div>

          <div className="pdfName">{cleanFileName(pdfs[path].name)}</div>
        </div>
      )
    })
  }

  return (
    <div className={`thingCard__wrapper static ${changeType} show`} key={props.id}>
      <ImageBox urls={files}></ImageBox>
      <div className="thingCard__contentBox">
        {data.name.length > 0 ? <div className="thingCard__name">{data.name}</div> : null}
        {data.description.length > 0 ? (
          <div className="thingCard__description">{data.description}</div>
        ) : null}
        {Object.keys(pdfs).length > 0 ? <div className="thingCard__pdfBox">{getPdfs()}</div> : null}
        {/* <div className="thingCard__bottomBar right"> */}
        {/* <div>..</div> */}
        <div className="thingCard__edit">
          <Button
            type={EButtonType.overlay}
            round
            // className="thingCard__edit"
            onClick={() => history.push(`getthing/thingid/${id}`)}
          >
            {Object.keys(files).length > 0 ? (
              <i className="material-icon white shadowSharpe">edit</i>
            ) : (
              <i className="material-icon black ">edit</i>
            )}
          </Button>
        </div>
        {/* </div> */}

        <div className="thingCard__uploadsWrapper">
          {/* {uploadStatus()} */}
          <div
            key={id}
            className={`thingCard__uploadsProgress ${uploadWIP ? "" : "hide"}`}
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}
export default ThingCard

//###################################################
//
//      ImageBox
//
//###################################################

interface IImageBox {
  urls: Array<string>
}
type TImagePreload = {
  [key: string]: {
    image: any
    loaded: boolean
    width: number
    height: number
  }
}
export const ImageBox: React.FunctionComponent<IImageBox> = (props) => {
  const [imageID, setImageID] = useState(0) // Holds the current shown image
  const [images, setImages] = useState<TImagePreload>({}) // holds the images an their preloading state.
  const [imagesBig, setImagesBig] = useState(false) // weather the image should be shown fullscreen

  // gerenartes the pagination
  const pagination = () => {
    if (props.urls.length > 1) {
      return props.urls.map((urls, id) => {
        return (
          <div
            className={`thingCard__imagePagination ${imageID === id ? "select" : ""}`}
            key={id}
          ></div>
        )
      })
    }
  }

  // shuffels the image ID
  const shuffleImages = (delta: number) => {
    setImageID((id) => (id + delta) % props.urls.length)
  }

  // setup preloading for the images
  useEffect(() => {
    console.log("======= INIT ====")
    const _imagesInit: TImagePreload = {}
    props.urls.map((url, id) => {
      _imagesInit[url] = { image: null, loaded: false, width: 0, height: 0 }
    })
    setImages(_imagesInit)
  }, [props.urls])

  // preloade the next image
  useEffect(() => {
    const nextImageId = (imageID + 1) % props.urls.length
    let prevImageId = (imageID - 1) % props.urls.length
    if (prevImageId < 0) {
      prevImageId = props.urls.length + prevImageId
    }
    console.log(`${prevImageId} - ${imageID} - ${nextImageId}`)

    const _urls = [props.urls[prevImageId], props.urls[imageID], props.urls[nextImageId]] // current & next image
    // const _urls = [props.urls[imageID]] // current & next image

    _urls.map((url, id) => {
      const image = new Image()
      image.src = url
      console.log("complete: ", image.complete)
      // if (!image.complete) {
      image.onload = () => {
        setImages((imgs) => ({
          ...imgs,
          [url]: { image: image, loaded: true, width: image.width, height: image.height },
        }))
      }
      // }
    })
  }, [imageID, props.urls])

  const getImages = (_images: TImagePreload) => {
    const ids: Array<string> = Object.keys(_images)

    return ids.map((url, id) => {
      // console.log("==> ", images[id].image.src)
      if (_images[url].loaded) {
        return (
          <img
            className={`thingCard__image ${id === imageID ? "show" : ""}`}
            src={_images[url].image.src}
            alt=""
            width={_images[url].image.width}
            height={_images[url].image.height}
          />
        )
      } else {
        return <div className={`image loading`} key={id} />
      }
    })
  }

  const height = (_id: number): any => {
    let height = 0
    if (images[props.urls[_id]]) {
      height = images[props.urls[_id]].height / images[props.urls[_id]].width
      if (height > 2) height = 1.5
    }

    const style = { height: `calc(${height} * var(--thingCardWidth))` }

    return style
  }

  // const fitImage = () => {
  //   if ()
  // }

  if (props.urls.length > 0) {
    return (
      <div
        className={`thingCard__imageBox ${imagesBig ? "big" : ""}`}
        // style={{ height: `calc(${height(imageID)} * var(--thingCardWidth))` }}
      >
        <div
          className={`thingCard__imagesWrapper `}
          style={height(imageID)}
          onClick={() => {
            shuffleImages(1)
          }}
        >
          <div className="imageFrame">
            {getImages(images)}

            <div className="thingCard__imagePaginationWrapper">{pagination()}</div>
            <div className="thingCard__expandImage">
              <Button
                type={EButtonType.overlay}
                round
                onClick={(e) => {
                  e.stopPropagation()
                  setImagesBig((state) => !state)
                }}
              >
                <i className="material-icon thingCard__expandImage_icon">
                  {imagesBig ? "fullscreen_exit" : "fullscreen"}
                </i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
