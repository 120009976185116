import React, { useEffect, useState } from "react"
import AppStore, { TThingData, TPublicLinkData } from "@awailio/backend/appStore/AppStore"
import { RouteComponentProps } from "react-router"
import ThingEditor, { EThingEditorTask } from "../thingEditor/ThingEditor"

//import "./cardDetail.css"

enum EIDType {
  pubid = "pubid",
  thingid = "thingid",
}

interface ICardDetail {
  uuid: string
  idtype: EIDType
}

enum ECardState {
  unknown = "unknown",
  thingData = "thingData",
  pubLink = "pubLink",
}

interface Props extends RouteComponentProps<ICardDetail> {}

const CardDetail: React.FunctionComponent<Props> = (props) => {
  const appStore = AppStore.useStore()
  const [linkData, setLinkData] = useState<ICardDetail | undefined>()
  const [thingData, setThingData] = useState<TThingData>()

  const [cardState, setCardState] = useState<ECardState>(ECardState.unknown)

  const [publicLinkData, setPublicLinkData] = useState<TPublicLinkData>()

  useEffect(() => {
    const _linkIDType: EIDType = props.match.params.idtype
    const _linkID: string = props.match.params.uuid

    setLinkData({
      idtype: _linkIDType,
      uuid: _linkID,
    })

    if (_linkIDType === EIDType.thingid) {
    }
  }, [props.match.params])

  useEffect(() => {
    if (linkData?.idtype === EIDType.thingid && linkData.uuid) {
      // whe have a thingLink & can show the data.
      setThingData(appStore.thingsSnapshot[linkData.uuid])
      setCardState(ECardState.thingData)
    } else if (linkData?.idtype === EIDType.pubid) {
      // whe have a pubLink lets see what's in

      // try to find the thing
      appStore.thingsCollection
        ?.where("publicLinkUUID", "==", linkData.uuid)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            console.log(doc.id)
          })
          if (snapshot.docs.length > 1) {
            appStore.addError("CardDetail", "to many things for object")
            // toDo: handle links pointing to more thean one thing
          } else if (snapshot.docs.length === 1) {
            const data = snapshot.docs[0].data()
            const thingUUID = snapshot.docs[0].id
            console.log("found doc", thingUUID)
            const publicLink: TPublicLinkData = {
              publicLinkUUID: data.publicLinkUUID,
              thingUUID: thingUUID,
            }

            if (data.publicLinkUUID !== linkData.uuid) {
              appStore.addError("CardDetail", "public link in thing does not match link in qrcode")
            }
            setPublicLinkData(publicLink)
            setCardState(ECardState.pubLink)
          }
        })
    } else {
      setCardState(ECardState.unknown)
    }
  }, [linkData, appStore])

  const content = (_cardState: ECardState) => {
    switch (_cardState) {
      case ECardState.thingData:
        return (
          <div>
            {linkData?.uuid ? (
              <ThingEditor task={EThingEditorTask.edit} thingID={linkData.uuid}></ThingEditor>
            ) : null}
          </div>
        )

      case ECardState.pubLink:
        return (
          <div>
            {publicLinkData?.thingUUID ? (
              <ThingEditor
                task={EThingEditorTask.edit}
                thingID={publicLinkData?.thingUUID}
              ></ThingEditor>
            ) : null}
          </div>
        )

      default:
        return (
          <div>
            You seems not to own this thing {props.match.params.uuid}
            {cardState} {JSON.stringify(thingData)}
          </div>
        )
    }
  }

  return (
    <div className="">
      <div>{content(cardState)}</div>
    </div>
  )
}
export default CardDetail
