import React from "react"

// import "./button.css"

export enum EButtonType {
  inactive = "inactive",
  default = "default",
  primary = "primary",
  danger = "danger",
  plain = "plain",
  plainDanger = "plainDanger",
  overlay = "overlay",
  overlayDark = "overlayDark",
  transparent = "transparent",
  darkOutline = "darkOutline",
}

interface IButton {
  type?: EButtonType
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  minWidth?: number
  minHeight?: number
  className?: string
  fill?: boolean
  round?: boolean
}

const Button: React.FunctionComponent<IButton> = (props) => {
  const addStyle = () => {
    let style: React.CSSProperties = {}
    if (props.minWidth && props.minHeight) {
      style = { minWidth: `${props.minWidth}rem`, minHeight: `${props.minHeight}rem` }
    } else if (props.minWidth) {
      style = { minWidth: `${props.minWidth}rem` }
    } else if (props.minHeight) {
      style = { minHeight: `${props.minHeight}rem` }
    } else if (props.fill) {
      style = { minWidth: `100%` }
    }
    return style
  }

  return (
    <button
      onClick={(e) => {
        if (props.onClick) props.onClick(e)
      }}
      className={`${props.type} ${props.className} ${props.round ? "round" : ""}`}
      style={addStyle()}
    >
      {props.children}
    </button>
  )
}
export default Button
