import React, { useState } from "react"
import AppStore from "@awailio/backend/appStore/AppStore"
import GoogleButton from "@awailio/main/assets/signInWithGoolge/btn_google_signin_light_normal_web@2x.png"

import "./auth.css"

interface ILogin {}

const Login: React.FunctionComponent<ILogin> = (props) => {
  const appStore = AppStore.useStore()
  const [persistLogin, setPersistLogin] = useState(false)

  return (
    <div className={`auth__wrapper`}>
      <div>
        <h1>Awail.io</h1>
        <h2>Start organizing your things today </h2>
      </div>
      <button className="signup" onClick={() => appStore.login(persistLogin)}>
        <img
          src={GoogleButton}
          alt="Login with Google"
          width={`${382 / 2}px`}
          height={`${92 / 2}px`}
        />
      </button>
      <div className="auth__confirm_persist">
        <input
          type="checkbox"
          checked={persistLogin}
          onChange={(e) => setPersistLogin((prev) => !prev)}
        />
        Remember me on this Computer.
      </div>
    </div>
  )
}

export default Login
