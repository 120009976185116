import React, { useEffect, useState } from "react"
import AppStore from "@awailio/backend/appStore/AppStore"

//import "./userProfile.css"
interface IUserProfile {}

const UserProfile: React.FunctionComponent<IUserProfile> = (props) => {
  const appStore = AppStore.useStore()
  const [confirmtextDel, setConfirmTextDel] = useState("")
  const [canDelete, setCanDelete] = useState(false)

  const id = () => {
    if (appStore.userDisplayName) {
      return appStore.userDisplayName
    } else if (appStore.userEmail) {
      return appStore.userEmail
    }
  }

  useEffect(() => {
    if (confirmtextDel === id()) {
      setCanDelete(true)
    } else {
      setCanDelete(false)
    }
  }, [confirmtextDel])

  return (
    <div className="app__contentFrame">
      <h1>UserProfile</h1>

      <div className="dangerZone">
        <h2>Delete Account for {id()}</h2>
        <p>Deleting your Accout can't be undone!</p>
        <label>
          Type <b>{id()}</b> to delete the Account:
          <input
            className="thingEditor__textarea"
            type="text"
            value={confirmtextDel}
            onChange={(e) => setConfirmTextDel(e.target.value)}
          ></input>
          <p></p>
          <button
            className={`${canDelete ? "danger solid" : "inactive"} `}
            onClick={() => {
              appStore.deletUserAccount()
            }}
          >
            Delete Profile
          </button>
        </label>
      </div>
    </div>
  )
}
export default UserProfile
