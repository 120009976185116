import React, { useState, useEffect } from "react"
import AppStore from "@awailio/backend/appStore/AppStore"
import { Page, View, Image, Document, Text, StyleSheet, PDFViewer } from "@react-pdf/renderer"
import QRCode from "qrcode"

//import "./labelCreator.css"
interface ILabelCreator {}

type QRCodeData = {
  publicLinkUUID: string
  qrCodeDataUrl: string
}

const LabelCreator: React.FunctionComponent<ILabelCreator> = (props) => {
  const appStore = AppStore.useStore()
  const [publicLinkData, setPublicLinkData] = useState<Array<QRCodeData>>()

  useEffect(() => {
    appStore.thingsCollection
      ?.where("isUsed", "==", false)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          return Promise.all(
            snapshot.docs.map((doc) => {
              const snap = doc.data()

              return QRCode.toDataURL(`http://awail.io/getthing/pubid/${snap.publicLinkUUID}`, {
                scale: 10,
              }).then((qrCodeDataUrl) => {
                return { qrCodeDataUrl: qrCodeDataUrl, publicLinkUUID: snap.publicLinkUUID }
              })
            })
          )
            .then((res) => {
              setPublicLinkData(res)
              return true
            })
            .catch((err) => appStore.addError("LabelCreator", JSON.stringify(err)))
        }
      })
  }, [appStore])

  /* --------------------------------------------------------  PDF ---|> */
  // Create styles
  const styles = StyleSheet.create({
    page: {
      // display: "flex",
      width: "210mm",
      height: "297mm",
      position: "absolute",
      // backgroundColor: "red",
      top: "0mm",
      left: "0mm",
    },
    section: {
      position: "absolute",
      width: "210mm",
      height: "297mm",

      left: "0mm",
      display: "flex",
      paddingLeft: "7mm",
      paddingTop: "15mm",
      flexDirection: "row",
      flexWrap: "wrap",
      // backgroundColor: "lightgrey",
    },
    qrCode: {
      width: "20mm",
      height: "20mm",
      position: "absolute",
      margin: "2mmm",
      top: "0mm",
      left: "0mm",
    },
    label: {
      // flexGrow: 2,
      backgroundColor: "white",
      display: "flex",
      marginRight: "3mm",
      width: "63mm",
      height: "29.633mm",
      borderRadius: 10,
      borderStyle: "solid",
      borderColor: "#DDD",
      borderWidth: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    user: {
      position: "absolute",
      bottom: "2mm",
      left: "4mm",
      fontSize: "3mm",
    },
    url: {
      position: "absolute",
      top: "0mm",
      fontSize: "2mm",
    },
  })
  const QRCodesPDF = (_publicLinkData: Array<QRCodeData>) => {
    if (_publicLinkData) {
      const qrCodeLables = _publicLinkData.map((pubLink) => (
        <View style={styles.label} key={pubLink.publicLinkUUID}>
          <Image src={pubLink.qrCodeDataUrl} style={styles.qrCode} key={pubLink.publicLinkUUID} />
          <Text style={styles.user}>{appStore.userEmail}</Text>
        </View>
      ))
      return (
        <PDFViewer width="100%" height="2000px">
          <Document title="Labels" author="awail.io" producer="awail.io">
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>{qrCodeLables}</View>
            </Page>
          </Document>
        </PDFViewer>
      )
    }
  }
  /* <-------------------------------------------------------- PDF ---<| */

  return (
    <div className="">
      {/* <ul>
        {publicLinkData?.map((data) => {
          return (
            <li key={data.publicLinkUUID}>
              <a href={`http://localhost:3000/getthing/pubid/${data.publicLinkUUID}`}>
                {data.publicLinkUUID}
              </a>
            </li>
          )
        })}
      </ul> */}
      {publicLinkData ? QRCodesPDF(publicLinkData) : null}
    </div>
  )
}
export default LabelCreator
