import React, { useEffect } from "react"
import AppStore, { TThingUploadFiles } from "@awailio/backend/appStore/AppStore"
import ThingCard from "@awailio/main/thingCard/ThingCard"
// import ThingEditor, { EThingEditorTask } from "@awailio/main/thingEditor/ThingEditor"

import "./thingsExplorer.css"
interface IThingsExplorer {}

const ThingsExplorer: React.FunctionComponent<IThingsExplorer> = (props) => {
  const appStore = AppStore.useStore()

  useEffect(() => {
    appStore.resetUploadState()
  }, [])

  const content = () => {
    if (appStore.thingsUsed.length > 0) {
      // console.log("used", appStore.thingsUsed)

      // appStore.thingsUsed.forEach((id) => alert(id))

      return appStore.thingsUsed.map((id) => {
        if (id !== "null") {
          return (
            <ThingCard
              key={id}
              id={id}
              data={appStore.thingsSnapshot[id]}
              fileUploads={appStore.uploadQueue[id]}
            ></ThingCard>
          )
        } else return null
      })
    }
  }

  // const showThingExplorer = (thingsUnused: string[]) => {
  //   if (thingsUnused.length > 0) {
  //     // return <ThingEditor task={EThingEditorTask.addThing}></ThingEditor>
  //     return <div>add thing</div> // <ThingEditor task={EThingEditorTask.addThing}></ThingEditor>
  //   } else {
  //     return (
  //       <div className="thingCard__wrapper static">
  //         No more free thing Slots left ? Do you need more ?
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className="thingsExplorer__cards">
      {/* {showThingExplorer(appStore.thingsUnused)} */}
      {content()}
    </div>
  )
}
export default ThingsExplorer
