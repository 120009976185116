import React, { useState, useEffect, useContext } from "react"
// import firebase from "firebase"

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"
import "firebase/firebase-auth"
import "firebase/functions"
import Compressor from "compressorjs"

// |-> firebaseConfig ------------------------------------- //
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

firebase.initializeApp(config)

let dbRef = firebase.firestore()
let storageRef = firebase.storage().ref()
// if (process.env.NODE_ENV === "development") {
//   firebase.auth().useEmulator("http://localhost:9099/")
//   dbRef.useEmulator("localhost", 8080)
//   firebase.functions().useEmulator("localhost", 5001)
// }

const db = dbRef.collection("users")
// firebaseConfig <-| ------------------------------------- //

export enum EAuthState {
  loggedOut = "loggedOut",
  processing = "processing",
  loggedIn = "loggedIn",
}

export type TPdfs = {
  [key: string]: string
}

// typed State for Firebase

export type TFile = File & {
  preview: string
}

export type TThingUserData = {
  name: string
  description: string
  files: Array<TFile>
}

export type TPublicLinkData = {
  publicLinkUUID: string
  thingUUID: string
}

export type TThingData = TThingUserData & {
  changeType: firebase.firestore.DocumentChangeType | null
  changeDate: firebase.firestore.FieldValue | null
  isUsed: boolean
  publicLinkUUID: string | null
}

export type TThingsSnapshot = {
  [key: string]: TThingData
}

export enum EUserInitState {
  intitialized = "intitialized",
  pending = "pending",
}

export type TThingUploadFiles = {
  [key: string]: number
}

export type TUploadQueue = {
  [key: string]: TThingUploadFiles
}
export type TErrorQueue = {
  [key: string]: string | React.ReactNode
}

export enum TUploadState {
  idle = "idle",
  inProgress = "inProgress",
  done = "done",
}

export type IState = {
  authState: EAuthState

  userEmail: string | null
  userDisplayName: string | null
  userUid: string | null
  userGroup: string | null
  userRefreshTime: number | null
  userInitState: EUserInitState | null
  userDBState: string | null

  userDataRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null
  thingsCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | null
  userStorage: firebase.storage.Reference | null
  uploadQueue: TUploadQueue

  thingsUsed: Array<string>
  thingsUnused: Array<string>

  errorQueue: TErrorQueue

  publicLinksDBref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | null

  thingsSnapshot: TThingsSnapshot
  focusThing: string | null

  errorMsg: string | null

  uploadProgress: number
  uploadInProgress: TUploadState

  showScanner: boolean
}

const initialState: IState = {
  authState: EAuthState.processing,
  userEmail: null,
  userDisplayName: null,
  userUid: null,
  userGroup: null,
  userRefreshTime: null,
  userInitState: EUserInitState.pending,
  userDBState: null,

  userDataRef: null,
  thingsCollection: null,
  userStorage: storageRef,
  uploadQueue: {},

  thingsUsed: [],
  thingsUnused: [],

  errorQueue: {},

  publicLinksDBref: null,

  thingsSnapshot: {},
  focusThing: null,

  errorMsg: null,

  uploadProgress: 0,

  uploadInProgress: TUploadState.idle,

  showScanner: false,
}

export type IAppStore = IState & {
  login: (setPersistence: boolean) => void
  logout: () => void
  addThing: (data: TThingUserData) => Promise<boolean>
  updateThing: (data: TThingUserData, thingID: string) => void
  addThingAssignPubLink: (data: TThingUserData, pubLinkID: string) => void
  deleteThing: (id: string) => Promise<boolean>
  deletUserAccount: () => void

  addError: (id: string, err: string | React.ReactNode) => void
  removeError: (id: string, all?: boolean) => void

  addUploadMessage: (thingId: string, fileId: string, progress: number) => void

  resetUploadState: () => Promise<boolean>

  toggleScanner: (newState: boolean) => void
}
function createStore() {
  const Context = React.createContext<IAppStore>({
    ...initialState,
    login: () => undefined,
    logout: () => undefined,
    addThing: () => new Promise((res, rej) => res(true)),
    updateThing: () => undefined,
    addThingAssignPubLink: () => undefined,
    deleteThing: () => new Promise((res, rej) => res(true)),
    deletUserAccount: () => undefined,
    addError: () => undefined,
    removeError: () => undefined,
    addUploadMessage: () => undefined,
    resetUploadState: () => new Promise((res, rej) => res(true)),
    toggleScanner: () => undefined,
  })

  //###################################################
  //###################################################
  //###################################################
  //
  //      Provider
  //
  //###################################################

  function Provider({ children }: { children: React.ReactNode }) {
    const [state, setState] = useState<IState>(initialState)
    const [unsubscribers, setUsubscribers] = useState<Array<() => void>>([])

    //###################################################
    //###################################################
    //###################################################
    //
    //      onAuthStateChanged
    //
    //###################################################

    useEffect(() => {
      const _unsubscribers: Array<() => void> = []
      firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
        if (user) {
          const unsubUserData = db.doc(user.uid).onSnapshot((snapshot) => {
            const data = snapshot.data()
            console.log("userData Update:", data)

            //
            firebase
              .auth()
              .currentUser?.getIdTokenResult(true)
              .then((idTokenResult) => {
                console.log("idTokenResult: ", idTokenResult)
                setState((prevState) => ({
                  ...prevState,
                  userGroup: idTokenResult.claims.userGroup,
                  userInitState: data?.userInitState,
                  userDBState: data?.userDBState,
                  useEmail: data?.userEmail,
                }))
              })
          })

          _unsubscribers.push(unsubUserData)
          //--->
          const thingsCollection = db.doc(user.uid).collection("things")
          _unsubscribers.push(subscribeThingsDB(thingsCollection))
          _unsubscribers.push(subscribeUnusedThings(thingsCollection))
          setUsubscribers(_unsubscribers)
          //<---

          //--->
          const pubLinksRef = db.doc(user.uid).collection("linkData")
          // unsubscribePublicLinksDB = subscribePubLinksDB(pubLinksRef)
          //<---

          const userRef = db.doc(user.uid)

          setState((prevState) => ({
            ...prevState,
            authState: EAuthState.loggedIn,
            userDisplayName: user.displayName,
            userEmail: user.email,
            userUid: user.uid,
            userDataRef: userRef,
            thingsCollection: thingsCollection,
            publicLinksDBref: pubLinksRef,
          }))
        } else {
          console.log("unsubscribe", unsubscribers)

          //unsubscribers.forEach((unsubscibe) => unsubscibe())

          setState((prevState) => ({
            ...prevState,
            authState: EAuthState.loggedOut,
            userDisplayName: null,
            userEmail: null,
            userUid: null,
            thingsCollection: null,
          }))
        }
      })

      // remove the listener
      return () => {
        unsubscribers.forEach((unsubscibe) => unsubscibe())
      }
    }, [])

    //###################################################
    //
    //      login
    //
    //###################################################
    const login = (setPersistence: boolean) => {
      console.log("[fire][auth]: login google: ")

      let persistence: string = firebase.auth.Auth.Persistence.SESSION
      if (setPersistence) {
        persistence = firebase.auth.Auth.Persistence.LOCAL
      }

      firebase
        .auth()
        .setPersistence(persistence)
        .then(() => {
          const authProvider = new firebase.auth.GoogleAuthProvider()

          return firebase.auth().signInWithRedirect(authProvider)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    //###################################################
    //
    //      logout
    //
    //###################################################
    function logout() {
      return new Promise((res, rej) => res(true))
        .then((i) => {
          console.log(i, "===>", unsubscribers)
          unsubscribers.forEach((unsubscibe) => unsubscibe())
        })
        .then(() => console.log("----"))
        .then(() =>
          firebase
            .auth()
            .signOut()
            .then(
              () => {
                console.log("[fire][auth]: logout succesful.")

                setState((prevState) => ({
                  ...prevState,
                  authState: EAuthState.loggedOut,
                  userDisplayName: null,
                  userEmail: null,
                }))
              },
              (reason) => {
                console.log("[fire][auth]: logout error", reason)
              }
            )
            .then(() => {
              // unsubscribeThingsDB()
              // unsubscribePublicLinksDB()
            })
        )
    }

    //###################################################
    //
    //      unusedThings
    //
    //###################################################
    const subscribeUnusedThings = (
      ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => {
      const unsubscribe = ref.where("isUsed", "==", false).onSnapshot((snapshot) => {
        const unsusedIDs = snapshot.docs.map((doc) => {
          return doc.id
        })
        setState((prevState) => ({ ...prevState, unusedThingIDs: unsusedIDs }))
      })

      return unsubscribe
    }

    //###################################################
    //
    //      subscribeThingsDB & handle Things
    //
    //###################################################
    const subscribeThingsDB = (
      ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    ) => {
      const unsubscribe = ref.onSnapshot((snapshot) => {
        // save used & unused thingIDs
        let thingsUsed: Array<string> = []
        let thingsUnused: Array<string> = []

        snapshot.docs.forEach((doc) => {
          const data = doc.data()
          if (data.isUsed === true) {
            thingsUsed.push(doc.id)
          } else {
            thingsUnused.push(doc.id)
          }
        })

        let things: TThingsSnapshot = Object.assign({}, state.thingsSnapshot)
        snapshot.docs.forEach((doc) => {
          const { name, description, publicLinksUUID, changeDate, isUsed, files } = doc.data()

          things[doc.id] = {
            name: name,
            description: description,
            files: files,
            changeType: null,
            changeDate: changeDate,
            isUsed: isUsed,
            publicLinkUUID: publicLinksUUID,
          }
        })

        // sort things by last changed
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added" || change.type === "modified") {
            const changedID = change.doc.id
            things[changedID].changeType = change.type

            const indexInUsedThings = thingsUsed.indexOf(changedID)
            if (indexInUsedThings > 0) {
              const move = thingsUsed.splice(indexInUsedThings, 1)[0]
              thingsUsed.unshift(move)
            }
          }
        })

        setState((prevState) => ({
          ...prevState,
          thingsSnapshot: things,
          thingsUsed: thingsUsed,
          thingsUnused: thingsUnused,
        }))
      })

      return unsubscribe
    }

    //###################################################
    //
    //      addthing
    //
    //###################################################
    // const addThing = (data: TThingUserData) => {
    //   return new Promise((resolve, reject) => {})
    // }

    const addThing = (data: TThingUserData): Promise<boolean> => {
      // create slots for the files
      setState((state) => ({ ...state, uploadInProgress: TUploadState.inProgress }))
      return new Promise((resolve, reject) => {
        state.thingsCollection
          ?.where("isUsed", "==", false)
          .limit(1)
          .get()
          .then((snapshot) => {
            const freeSlot = snapshot.docs[0]
            if (freeSlot) {
              console.log(freeSlot.id)
              setState((state) => ({ ...state, focusThing: freeSlot.id }))
              state.thingsCollection
                ?.doc(freeSlot.id)
                .update({
                  isUsed: true,
                  name: data.name,
                  description: data.description,
                  changeDate: firebase.firestore.Timestamp.now().toMillis(),
                })
                .then(() => {
                  // alert(data.files.length)
                  if (data.files.length > 0) {
                    uploadFiles(data.files, freeSlot.id)
                  } else {
                    setState((state) => ({
                      ...state,
                      uploadInProgress: TUploadState.done,
                      uploadProgress: 100,
                    }))
                  }
                })
                .catch(() => reject(false))
            }
          })
          .then(() => {
            resolve(true)
          })
        // resolve(true)
      })
    }

    //###################################################
    //
    //      resizeImage
    //
    //###################################################
    const resizeImage = async (image: TFile, width: number): Promise<Blob> => {
      return new Promise((resolve, reject) => {
        new Compressor(image, {
          quality: 0.4,
          maxWidth: width,
          maxHeight: width * 1.5,
          mimeType: "image/jpeg",

          success(file: Blob) {
            resolve(file)
          },
          error: reject,
        })
      })
    }

    //###################################################
    //
    //      uploadFiles
    //
    //###################################################
    const uploadFiles = (files: Array<TFile>, freeSlotID: string) => {
      const fileUploadsPR: Array<firebase.storage.UploadTask> = []
      let i = -1

      if (files.length > 0) {
        setState((state) => ({
          ...state,
          uploadInProgress: TUploadState.inProgress,
        }))
      }
      for (const file of files) {
        i++
        console.log("[apStore]: ", file.name)
        if (state.userUid) {
          // const imageRef = storageRef.child(`${file.name}`)
          if (file.type.includes("image")) {
            //###################################################
            //      Image
            //###################################################
            resizeImage(file, 1200)
              .then((blob: Blob) => {
                console.log("res:", blob)

                // -------
                let newMetadata = {
                  cacheControl: "public,max-age=4000",
                }
                const imageRef = storageRef.child(
                  `userData/${state.userUid}/files/${freeSlotID}/images/${file.name}`
                )
                const uploadTask = imageRef.put(blob, newMetadata)
                fileUploadsPR.push(uploadTask)
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    addUploadObject(freeSlotID, file.name, progress)
                  },
                  (err) => {
                    setState((state) => ({
                      ...state,
                      errorQueue: {
                        ...state.errorQueue,
                        [file.name]: JSON.stringify(err),
                      },
                    }))
                  },
                  () => {
                    // alert("upload done for: " + file.name)
                    addUploadObject(freeSlotID, file.name, 100)
                  }
                )
              })
              .catch((err) => addError(file.name, <>{JSON.stringify(err)}</>))
          } else if (file.type.includes("pdf")) {
            //###################################################
            //      files
            //###################################################
            let newMetadata = {
              cacheControl: "public,max-age=4000",
            }
            const imageRef = storageRef.child(
              `userData/${state.userUid}/files/${freeSlotID}/files/${file.name}`
            )
            const uploadTask = imageRef.put(file, newMetadata)
            fileUploadsPR.push(uploadTask)
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                addUploadObject(freeSlotID, file.name, progress)
              },
              (err) => {
                setState((state) => ({
                  ...state,
                  errorQueue: {
                    ...state.errorQueue,
                    [file.name]: JSON.stringify(err),
                  },
                }))
              },
              () => {
                console.log("upload done for: ", file.name)
                addUploadObject(freeSlotID, file.name, 100)
              }
            )
          }
        }
      }

      return Promise.all(fileUploadsPR)
        .then((res) => {
          console.log("all uploaded: ", res, state.uploadQueue)
          // removeUploadObject(freeSlotID)
        })
        .catch((err) => {
          console.error("file upload error:", err)
        })
    }

    //###################################################
    //
    //      fileUploadProgress
    //
    //###################################################

    useEffect(() => {
      let progress = 0
      let uploadsCount = 0

      if (state.uploadQueue) {
        const uploadContexts = Object.keys(state.uploadQueue)
        if (uploadContexts) {
          uploadContexts.forEach((thingID) => {
            const uploads = state.uploadQueue[thingID]

            if (uploads) {
              const uploadsKeys = Object.keys(uploads)
              uploadsKeys.map((file, id) => {
                uploadsCount++
                progress += uploads[file]
              })
            }
          })
        }

        if (uploadsCount > 0) {
          progress = progress / uploadsCount
          setState((state) => ({
            ...state,
            uploadProgress: progress,
          }))
        }

        if (progress === 100) {
          setState((state) => ({
            ...state,
            uploadInProgress: TUploadState.done,
          }))
        }
      }
    }, [state.uploadQueue])

    //###################################################
    //
    //      updateThing
    //
    //###################################################

    const updateThing = (data: TThingUserData, thingID: string) => {
      setState((state) => ({
        ...state,
        uploadInProgress: TUploadState.inProgress,
      }))
      state.thingsCollection
        ?.doc(thingID)
        .update({
          name: data.name,
          description: data.description,
          isUsed: true,
          changeDate: firebase.firestore.Timestamp.now().toMillis(),
        })
        .then(() => {
          if (data.files.length > 0) {
            uploadFiles(data.files, thingID)
          } else {
            setState((state) => ({
              ...state,
              uploadProgress: 100,
              uploadInProgress: TUploadState.done,
            }))
          }
        })
        .then((res) => console.log(":::> ", res))
    }

    //###################################################
    //
    //      deleteThing
    //
    //###################################################

    const deleteThing = (id: string): Promise<boolean> => {
      console.log("delete")

      return new Promise((resolve, reject) => {
        state.thingsCollection
          ?.doc(id)
          .update({
            changeDate: firebase.firestore.Timestamp.now().toMillis(),
            name: null,
            description: null,
            isUsed: false,
          })
          .then(() => {
            if (state.userStorage) {
              state.userStorage
                .child(`userData/${state.userUid}/files/${id}/images`)
                .listAll()
                .then((images) => {
                  images.items.map((item) => {
                    item.delete()
                  })
                })
              state.userStorage
                .child(`userData/${state.userUid}/files/${id}/files`)
                .listAll()
                .then((files) => {
                  files.items.map((item) => {
                    item.delete()
                  })
                })
            }
          })
          .then(() => resolve(true))
          .catch((err) => {
            setErrorMsg(JSON.stringify(err))
            reject(false)
          })
      })
    }

    const setErrorMsg = (error: string) => {
      setState((prevState) => ({ ...prevState, errorMsg: error }))
    }

    //###################################################
    //
    //      addThingAssignPubLink
    //
    //###################################################
    const addThingAssignPubLink = (data: TThingUserData, pubLinkID: string) => {
      console.log("[addThingAssignPubLink]: ", data, pubLinkID)
      const addThingAssignPubLink = firebase.functions().httpsCallable("addThingAssignPubLink")
      addThingAssignPubLink({
        name: data.name,
        description: data.description,
        pubLinkID: pubLinkID,
      })
        .then((res) => {
          console.log("RES", res)
        })
        .catch((err) => console.log("ERR", err))
    }

    //###################################################
    //
    //      deletUserAccount
    //
    //###################################################

    const deletUserAccount = () => {
      console.log("delete")

      const delAcount = firebase.functions().httpsCallable("deletUserAccount")
      delAcount()
        .then((res) => {
          console.log("Delete Account:", res)
          logout()
        })
        .catch((err) => console.error(err))
    }

    //###################################################
    //
    //      error messages
    //
    //###################################################

    const addError = (id: string, err: string | React.ReactNode) => {
      setState((state) => ({ ...state, errorQueue: { ...state.errorQueue, [id]: err } }))
    }

    const removeError = (id: string, all?: boolean) => {
      let newErrorQ: TErrorQueue = { ...state.errorQueue }

      if (!all) {
        newErrorQ = { ...state.errorQueue }
        delete newErrorQ[id]
      } else {
        newErrorQ = {}
      }

      setState((state) => {
        return { ...state, errorQueue: newErrorQ }
      })
    }

    //###################################################
    //
    //      upload messages
    //
    //###################################################

    const addUploadObject = (thingId: string, fileId: string, progress: number) => {
      setState((state) => {
        return {
          ...state,
          uploadInProgress: TUploadState.inProgress,
          uploadQueue: {
            ...state.uploadQueue,
            [thingId]: { ...state.uploadQueue[thingId], [fileId]: progress },
          },
        }
      })
    }

    const removeUploadObject = (thingId: string, all?: boolean) => {
      console.log("removeUploadObject >>", state)

      setState((state) => ({
        ...state,
        uploadQueue: {},
      }))
    }

    const resetUploadState = (): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        setState((state) => ({
          ...state,
          uploadQueue: {},
          uploadInProgress: TUploadState.idle,
          uploadProgress: 0,
        }))
        resolve(true)
      })
    }

    const toggleScanner = (newState: boolean) => {
      setState((state) => ({
        ...state,
        showScanner: newState,
      }))
    }
    //###################################################
    //###################################################
    //###################################################
    //
    //      contextObject
    //
    //###################################################
    const contextObject: IAppStore = {
      ...state,
      login: (setPersistence: boolean) => login(setPersistence),
      logout: () => logout(),
      addThing: (data: TThingUserData) => addThing(data),
      updateThing: (data: TThingUserData, thingID: string) => updateThing(data, thingID),
      addThingAssignPubLink: (data: TThingUserData, pubLinkID: string) =>
        addThingAssignPubLink(data, pubLinkID),
      deleteThing: deleteThing,
      deletUserAccount: () => deletUserAccount(),
      addError: addError,
      removeError: removeError,

      addUploadMessage: addUploadObject,
      resetUploadState: resetUploadState,

      toggleScanner: toggleScanner,
    }
    return <Context.Provider value={contextObject}>{children}</Context.Provider>
  }
  const useStore = () => useContext<IAppStore>(Context)
  return { Provider, useStore }
}

const AppStore = createStore()
export default AppStore
