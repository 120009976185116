import React from "react"
import { useHistory, useLocation } from "react-router"
import Scanner from "@awailio/main/scanner/Scanner"
import AppStore from "@awailio/backend/appStore/AppStore"

//import "./scannerMain.css"
interface IScannerMain {}

const ScannerMain: React.FunctionComponent<IScannerMain> = (props) => {
  const appStore = AppStore.useStore()
  const history = useHistory()

  const getScan = (scan: string) => {
    const regex = /http:\/\/awail\.io\/getthing\/pubid\/(.*?)$/
    const results = regex.exec(scan)
    if (results?.[1]) {
      const result = results?.[1]
      console.log("scannes id: ", result)
      //   setScannedPubID(result)
      appStore.toggleScanner(false)
      history.push(`/getthing/pubid/${result}`)
    }
  }

  return (
    <>
      {appStore.showScanner ? (
        <div className="app_mainScanner">
          <Scanner onScanCB={(scan) => getScan(scan)}></Scanner>
        </div>
      ) : null}
    </>
  )
}
export default ScannerMain
