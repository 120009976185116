import React, { useState } from "react"
import QrReader from "react-qr-reader"

//import "./scanner.css"
interface IScanner {
  onScanCB: (scan: string) => void
}

const Scanner: React.FunctionComponent<IScanner> = (props) => {
  const [scan, setScan] = useState<string>()
  const handleError = (err: any) => {
    console.log(err)
  }
  const handleScan = (newScan: string | null) => {
    // console.log(scan)
    if (newScan) {
      if (newScan !== scan) {
        setScan(scan)
        props.onScanCB(newScan)
      }
    }
  }

  return (
    <div>
      <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: "100%" }} />
    </div>
  )
}
export default Scanner
